/******* screen-large.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
.navi-bottom div.navi {
  float: left;
  display: block;
}
.navi-bottom div.navi > .item {
  float: left;
}
.navi-bottom div.navi > .item > .menu {
  display: block;
  color: #868889;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}
.navi-bottom div.navi > .item > .menu.path {
  color: #cc071e;
}
.navi-bottom div.sub1 {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: calc(100% - 44px);
}
.navi-bottom div.sub1 > .item {
  position: relative;
  flex: 1 0 100px;
}
.navi-bottom div.sub1 > .item > .menu {
  padding: 10px;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.2;
}
.navi-bottom div.sub1 > .item > .menu:hover,
.navi-bottom div.sub1 > .item > .menu:focus {
  color: #cc071e;
}
.navi-bottom div.sub2 {
  position: absolute;
  z-index: 200;
  margin-top: 0;
}
.navi-bottom div.sub2 > .item {
  overflow: hidden;
  max-height: 0;
  width: 100%;
  transition: max-height 250ms ease;
}
.navi-bottom div.sub2 > .item > .menu {
  border-top: 1px solid #fff;
  padding: 7px 15px;
  background-color: rgba(255, 255, 255, 0.7);
}
.navi-bottom div.sub2 > .item > .menu:hover,
.navi-bottom div.sub2 > .item > .menu:focus {
  color: #cc071e;
}
.navi-bottom div.sub2 > .item > .menu.path {
  background-color: #fff;
}
div.sub1 > .item:hover div.sub2 > .item {
  max-height: 150px;
  transition: max-height 700ms ease;
}
.desk {
  max-width: calc(100% - 60px);
}
#home {
  width: 22.5%;
  height: auto;
  margin: 35px 1.25% 15px 2.5%;
}
#head {
  padding: 0 1.72413793%;
}
.navbar {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-end;
}
.navis {
  display: block;
  float: right;
  width: 70%;
  margin-left: 1.25%;
  margin-right: 2.5%;
}
.navi-top,
.navi-bottom {
  float: right;
}
.navi-top {
  margin-top: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navi-top #find fieldset {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.navi-top #find .find {
  width: 20px;
}
.navi-bottom {
  width: 100%;
  margin-top: 15px;
}
.vcard,
#servicesContact {
  padding: 0 1.72413793%;
}
.services {
  padding: 0 2.5%;
}
.container--left {
  width: 72.5%;
  margin-left: 1.25%;
  margin-bottom: 30px;
}
.container--right {
  float: right;
  width: 22.5%;
  margin-left: 0;
  margin-right: 2.5%;
}
.area.main {
  width: 100%;
}
.area.main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area.main .foot {
  margin-right: 1.72413793%;
  margin-left: 1.72413793%;
  width: 96.55172414%;
}
.area.main .part {
  margin-right: 1.72413793%;
  margin-left: 1.72413793%;
  width: 96.55172414%;
}
.area.main .tiny {
  width: 29.88505747%;
}
.area.main > .slim {
  width: 33.33333333%;
}
.area.main > .slim .foot,
.area.main > .slim .part {
  margin-right: 5.17241379%;
  margin-left: 5.17241379%;
}
.area.main > .slim .part {
  width: 89.65517241%;
}
.area.main > .slim .tiny {
  width: 39.65517241%;
}
.area.side {
  width: 100%;
}
.area.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area.side .foot {
  margin-right: 2.77777778%;
  margin-left: 2.77777778%;
  width: 94.44444444%;
}
.area.side .part {
  margin-right: 2.77777778%;
  margin-left: 2.77777778%;
  width: 94.44444444%;
}
.area.side .tiny {
  width: 44.44444444%;
}
.area.side > .slim .part {
  width: 94.44444444%;
}
.area.side > .slim .tiny {
  width: 44.44444444%;
}
.area.main .unit.seam.wide {
  width: 66.66666667%;
}
.area.main .unit.seam.wide .part {
  margin-left: 2.5862069%;
  margin-right: 2.5862069%;
}
.area.main .unit.seam.wide .part.tall {
  width: 94.82758621%;
}
.area.main .unit.seam.wide .part.tiny {
  width: 44.82758621%;
}
.area.side {
  padding: 0 6px;
}
.area .unit.form.wide .cb-form-required {
  width: 64.88095238%;
  margin-left: 35.11904762%;
}
.area .unit.form.wide .tile .name {
  width: 31.54761905%;
}
.area .unit.form.wide .tile input.text,
.area .unit.form.wide .tile textarea {
  width: 64.88095238%;
}
.area .unit.form.wide input.submit,
.area .unit.form.wide button.cb-hybrid {
  width: 30.71428571%;
  margin-left: 35.11904762%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -600px;
  width: 1200px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*# sourceMappingURL=./screen-large.css.map */